import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../context";
import { ImageDisplay } from "./";

const CategoryNewsDisplay = ({ cat, id, cols }) => {
  const { apiUrl, siteconfig, allNews, isMobile } = useContext(SiteContext);

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let filteredNews = allNews
      .filter((item) => parseInt(item.newscatid) === parseInt(id))
      .slice(0, 3);
    setData(filteredNews);
  };

  if (data.length < 2) {
    return null;
  }

  return (
    <div className="my-1 py-1 mx-0 container">
      <div className="h3 my-1 text-dark">
        <span style={{ color: "#ff0000" }} className="h2">
          |{" "}
        </span>{" "}
        <span className="h3">{cat.toUpperCase()}</span>
      </div>
      <div className="row">
        {data.map((item, i) => {
          return (
            <div className={`col-md-${cols}`}>
              <Link
                className="text-decoration-none text-dark"
                to={`/${item.newscatid}/${item.ncat.toLowerCase()}/${
                  item.newsid
                }/${item.ntitle_slug}`}
              >
                {!isMobile && (
                  <ImageDisplay
                    url={`${item.nphoto}`}
                    title={item.ntitle}
                    category={item.ncat}
                    height={210}
                  />
                )}
                <div className={isMobile ? "h6 py-2 border-bottom" : "h5 py-2"}>
                  {item.ntitle}
                </div>
                {!isMobile && <div>{item.nbrief.split(" ", 10).join(" ")}</div>}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryNewsDisplay;
