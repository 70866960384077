import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  VideoDisplay,
  CategoryNewsDisplay,
  BannerTop,
  BannerSide,
} from "../components";
import { aboutImage, localTerrain } from "../assets";
import Youtube from "react-youtube";
import { removeTags, getHeading } from "../utils";
import Carousel from "react-bootstrap/Carousel";
import Accordion from "react-bootstrap/Accordion";
import Seo from "./Seo";

const Home = () => {
  const {
    userData,
    apiUrl,
    loading,
    siteconfig,
    allNews,
    allCats,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const navigate = useNavigate();

  const [featured, setFeatured] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const [videoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    handleCurrentPage("home");
    getSections();
    getAllVideos();
  }, []);

  const getSections = () => {
    let filteredFeatured = allNews
      .filter((item) => item.newsflag === "featured")
      .slice(0, 5);

    setFeatured(filteredFeatured);
  };

  const getAllVideos = () => {
    setVideoLoading(true);
    return fetch("https://nayournews.com/api/allvideos.php?action=fetch", {
      method: "get",
    })
      .then(processResponse)
      .then(async (res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          await setAllVideos(data.videos);
          setVideoLoading(false);
        } else if (statusCode === 400) {
          if (data.error) {
            console.log(data.error);
          }
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const yId = (urllink) => {
    let vid = urllink.substring(31, 42);
    return vid;
  };

  const processResponse = (response) => {
    const statusCode = response.status;
    const data = response.json();
    return Promise.all([statusCode, data]).then((res) => ({
      statusCode: res[0],
      data: res[1],
    }));
  };

  const processText = (text) => {
    let thetext = removeTags(text);

    thetext = getHeading(thetext, 25);

    return thetext;
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-content-center my-5">
        <div class="spinner-border text-secondary" role="status" />
      </div>
    );
  }

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      <div className="container py-3 my-1">
        <div className="row">
          <div className="col-md-6 p-2">
            {allNews.slice(0, 1).map((item, i) => {
              if (
                item.nphoto === "" ||
                item.nphoto === undefined ||
                item.nphoto === null
              ) {
                return (
                  <Link
                    to={`/${item.newscatid}/${item.ncat.toLowerCase()}/${
                      item.newsid
                    }/${item.ntitle_slug}`}
                  >
                    <div
                      style={{
                        position: "relative",
                        bottom: 0,
                        height: isMobile ? 280 : 420,
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          backgroundColor: "#000000",
                          opacity: 0.7,
                          bottom: 0,
                          height: isMobile ? 280 : 420,
                          padding: 20,
                          color: "#ffffff",
                        }}
                      >
                        <p
                          style={{ textShadow: "inherit" }}
                          className={item.nbrief ? "h3" : "h6"}
                        >
                          {item.ntitle}
                        </p>

                        <div className="px-1">
                          <span className="text-danger h4"> | </span>
                          {item.ncat.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              } else {
                return (
                  <Link
                    to={`/${item.newscatid}/${item.ncat.toLowerCase()}/${
                      item.newsid
                    }/${item.ntitle_slug}`}
                  >
                    <ImageDisplay
                      url={`${item.nphoto}`}
                      title={item.ntitle}
                      showText
                      largeimg
                      category={item.ncat}
                      height={isMobile ? 280 : 420}
                    />
                  </Link>
                );
              }
            })}
          </div>
          <div className="col-md-6">
            <div className="row">
              {allNews.slice(1, 5).map((item, i) => {
                return (
                  <div className="col-md-6 p-1">
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/${item.newscatid}/${item.ncat.toLowerCase()}/${
                        item.newsid
                      }/${item.ntitle_slug}`}
                    >
                      {!isMobile && (
                        <ImageDisplay
                          url={`${item.nphoto}`}
                          title={item.ntitle}
                          showText
                          category={item.ncat}
                          height={210}
                        />
                      )}
                      {isMobile && (
                        <div className="container border-bottom py-1">
                          <div className="h6">{item.ntitle}</div>
                          <div>
                            <span className="text-danger"> | </span>
                            <span className="small">
                              {item.ncat.toUpperCase()}
                            </span>
                          </div>
                        </div>
                      )}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {allNews.length > 5 && (
          <div className="h3 my-1 text-dark">
            <span style={{ color: "#ff0000" }} className="h2">
              |{" "}
            </span>{" "}
            <span className="h3">MORE NEWS</span>
          </div>
        )}

        <div className="row">
          {allNews.slice(5, 17).map((item, i) => {
            return (
              <div className="col-md-4 p-1">
                <Link
                  className="text-decoration-none text-dark"
                  to={`/${item.newscatid}/${item.ncat.toLowerCase()}/${
                    item.newsid
                  }/${item.ntitle_slug}`}
                >
                  {!isMobile && (
                    <div className="row">
                      <div className="col-3">
                        <ImageDisplay
                          url={`${item.nphoto}`}
                          title={item.ntitle}
                          moreNews
                          category={item.ncat}
                          height={70}
                        />
                      </div>
                      <div className="col-9">
                        <div className="h6">{item.ntitle}</div>
                      </div>
                    </div>
                  )}
                  {isMobile && (
                    <div className="container border-bottom py-1">
                      <div className="h6">{item.ntitle}</div>
                    </div>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <BannerTop />

      <div style={{ backgroundColor: "#000000" }} className="my-2 py-3">
        <div style={{ overflowX: "scroll" }} className="container">
          {videoLoading && (
            <div className="d-flex justify-content-center align-content-center my-5">
              <div class="spinner-border text-secondary" role="status" />
            </div>
          )}
          {allVideos.length > 0 && (
            <div className="row">
              {allVideos.slice(0, 3).map((item, i) => {
                const opts = {
                  height: "240",
                  width: "100%",
                };

                return (
                  <div key={i} className="col-md-4 p-3">
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/video/${item.videntryid}/${item.vidtitle
                        .toLowerCase()
                        .split(" ")
                        .join("-")}/`}
                    >
                      <VideoDisplay
                        url={`${item.vidurl}`}
                        title={item.vidtitle}
                        showTitle
                        opts={opts}
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="container">
        <div className="row my-1">
          <div className="col-md-9">
            {allCats.map((item, i) => {
              return (
                <CategoryNewsDisplay
                  key={i}
                  cat={item.ncat}
                  id={item.ncatid}
                  cols={4}
                />
              );
            })}
          </div>
          <div className="col-md-3">
            <BannerSide />

            {featured.length > 0 && (
              <div className="container my-2 py-2">
                {" "}
                <h3>Featured Stories</h3>
                {featured.map((item, i) => {
                  return (
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/${item.newscatid}/${item.ncat.toLowerCase()}/${
                        item.newsid
                      }/${item.ntitle_slug}`}
                    >
                      <div
                        style={{ backgroundColor: "#cccccc" }}
                        className="row my-3 py-2 border-bottom"
                      >
                        <div className="col-md-12 mx-0 px-0 col-3">
                          <ImageDisplay
                            url={`${item.nphoto}`}
                            title={item.ntitle}
                            height={isMobile ? 80 : 200}
                          />
                        </div>
                        <div className="col-md-12 col-9">
                          <div className="h6 my-1 py-1">{item.ntitle}</div>
                          <div className="px-1">
                            <span className="text-danger h4"> | </span>
                            {item.ncat.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <BannerTop />

      <Footer />
    </div>
  );
};

export default Home;
